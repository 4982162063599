import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import MountingTypeThumbnailList from "../../../components/MountingTypeThumbnailList";

export default function MountingSurfacePage({ data }) {
  const mountings = data.mountings.nodes;
  const productImages = data.productImages.nodes;
  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Ratio Exterior Surface Range"
          key="ogtitle"
        />
      </Helmet>
      <main id="main">
        <div className="pt-small pb-small px-medium">
          <div className="">
            <MountingTypeThumbnailList
              mountings={mountings}
              productImages={productImages}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export const query = graphql`
  query {
    mountings: allAirtableMountings(
      filter: {
        data: {
          webMountingSurface: { eq: "Exterior" }
          webMountingType: { eq: "Surface" }
          wPublished: { eq: true }
        }
      }
    ) {
      nodes {
        data {
          Name
          T2 {
            recordId
            data {
              webHeroImage {
                data {
                  Filename
                }
              }
              webDisplayNameFirst
              # webDisplayNameSecond
              webDisplayNameSecondShort
              webFullName
              webPublishedResults
              webT4Slug
              webSlug
              webPower
              webFluxRange
              webDistribution
            }
          }
          webDisplayOrder
          # webMountingSurface
          # webMountingType
        }
      }
    }
    productImages: allImageSharp {
      nodes {
        gatsbyImageData(
          formats: JPG
          jpgOptions: { quality: 75 }
          sizes: "(min-width: 2000px) calc(25vw - 60px), (min-width: 1400px) calc(33.28vw - 63px), (min-width: 1100px) calc(50vw - 72px), (min-width: 600px) calc(50vw - 36px), calc(100vw - 32px)"
          placeholder: BLURRED
          width: 1200
        )
        parent {
          ... on File {
            relativePath
          }
        }
      }
    }
  }
`;
